.eshop-render-cards { 
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

.eshop-card-body {
    overflow: hidden;
    width: 300px;
    height: 400px;
    margin: 1% 0;
    border-radius: 5px;

}

.eshop-render-cards-title { 
    width: 95%;
    text-transform: uppercase;
    color: var(--cWhite);

}

.eshop-card-body:hover { 
    .eshop-card-info { 
        transform: translateY(-40%);
        box-shadow: 0 0 10px black;

        .eshop-card-description { 
            display: flex;
        }

        h3 { 
            color: var(--cGreen);
        }
    }
}

.eshop-card-element { 
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-transform: uppercase;
    width: 100%;
    height: 50%;

        
}

.eshop-card-image { 
    img { 
        padding: 5%;
        border-radius: 10%;
        width: 100%;
        height: 100%;
        background-size: contain;
        aspect-ratio: 16/9;
        object-fit: contain;
    }
}

.eshop-card-info { 
    padding: 15% 0;
    justify-content: start;
    text-align: center;
    
    object-fit: contain;
    height: 100%;

    .eshop-card-description { 
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        font-weight: 300;
        padding: 5% 5% ;

    }
    h3 { 
        font-size: 14px;
        object-fit: contain;
        width: 100%;
    }
    h4 { 
        color: var(--cGreen);
    }

    .eshop-card-details { 
        p { 
            font-size: 14px;
            font-weight: 200;
        }
    }


    .eshop-card-purchase-button { 
        padding: 5% 0;

        button { 
            background-color: var(--cGreen);
            width: 100px;
            height: 30px;
            border: 0;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: var(--aFont);
            text-transform: uppercase;
            font-weight: 600;
            color: var(--cWhite);
            cursor: pointer;
        }

    }
}

