#Landing { 
    height: 90vh;
    position: relative;
}


.landing-container  { 
    height: 100%;
    display: flex;
    flex-wrap: wrap;
}

.landing-split { 
    width: 50%;
    height: 100%;
}

#Landing strong { 
    color: var(--cGreen);
}

/* Left */

.landing-left  { 
    display: flex;
    align-self: center;
    justify-content: center;

}

.landing-left-content { 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 700px;
    padding:  0 80px;

}

.landing-left-title h1 { 
    font-size: 4rem;
    width: 100%;
    text-transform: uppercase;
    font-weight: 800;
    color: var(--cWhite);
    margin: 0;

    line-height: 1.3;
}

.landing-left-desc  {
    margin: 15px 0;
    width: 100%;
    text-align: left;
    display: flex;;
    align-items: center;
    justify-content: center;;
 } 


.landing-left-desc p { 
    margin: 0;
    width: 100%;
    font-size: 13px;
    color: var(--cWhite);
    font-weight: 300;
    text-transform: uppercase;

    @media only screen and (max-width: 768px) {
        text-align: center;
    }
}

.landing-left-target {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;

    button {
        width: 170px;
        height: 40px;
        border: none;
        border-radius: 10px;

        display: flex;;
        align-items: center;
        justify-content: center;;
    
        text-transform: uppercase;;
        text-align: center;
        font-family: var(--aFont);
        font-weight: 600;
        font-size: 20px;
        color: var(--cWhite);
    
        background-color: var(--cGreen);
    
        cursor: pointer;
    
        transition: .5s;
    }
} 


.landing-left-target button:hover { 
    background-color: var(--cGrey);
}

/* Right */

.landing-right { 
    display: flex;
    align-items: center;
    justify-content: center;
}


.landing-right img { 
    width: 75%;
}


@media only screen and (max-width: 768px) { 
    .landing-container { 
        justify-content: center;
        align-items: center;
    }

    #Landing .landing-right { 
        display: none;
    }

    #Landing .landing-right * { 
        display: none;
    }

    #Landing .landing-left { 
        text-align: center;
    }
    #Landing .landing-left-title h1 {
        font-size: 3.5rem;
    }
    #Landing .landing-left-desc p { 
        width: 100%;
    }

    #Landing .landing-left-target { 
        justify-content: center;
    }

}

