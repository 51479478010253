#navBar { 
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2% 5%;


    position: relative;
}

.nav-sites { 
    display: flex;
    justify-content: center;
    align-items: center;


    .nav-right-login {
        a { 
            color: var(--cWhite);

            :hover { 
                color: var(--cGreen);
            }
        }


    }


}


.nav-left { 
    gap: 100px;
}

.nav-right { 
    gap: 20px;
}

.nav-img {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
} 

.nav-img img {
    width: 75px;
}

.nav-buttons { 
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.nav-buttons a { 
    margin: 0 10px;
    text-decoration: none;
    color: var(--cWhite);
    font-family: var(--aFont);
    font-weight: 300;
    font-size: 17px;
    padding: 2% 0;
    transition: .5s;
}

.nav-socials { 
    display: flex;
    align-items: center;
    justify-content: centers;


    height: 100%;
}

.nav-socials a { 
    margin: 0 3px;
    text-decoration: none;
    color: white;
}

.nav-target-button { 
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    transition: .5s;
}

.nav-target-button button { 
    width: 150px;
    height: 40px;
    border-radius: 30px;
    border: none;
    background-color: var(--cGreen);

    color: var(--cWhite);
    font-family: var(--aFont);
    font-weight: 600;
    font-size: 16px;

    cursor: pointer;

    transition: .5s;
}

/* Hover */

.nav-buttons a:hover { 
    color: var(--cGreen);
    transform: translateY(-3px);
}

.nav-target-button:hover { 
    transform: scale(1.05);
}

.nav-target-button button:hover { 
    background-color: var(--cGrey);
}

.nav-ig:hover { 
    color: orange;
}

.nav-fb:hover { 
    color: rgb(63, 133, 255);
}

.nav-mobile-wrapper { 
    display: none;
}


.burger-menu { 
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
    padding: 20px 0;
}

.burger-menu a { 
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 2% 0;
    margin: 0.1% 0;

    color: var(--cWhite);
    text-decoration: none;
    text-transform: uppercase;
    font-size: 20px;
}

.burger-menu a:hover { 
    color: var(--cGreen);
}

.burger-target { 
    margin: 10px 0;
}

@media screen and (max-width:768px) {

    .burger-menu { 
        display: flex;
    }

    #navBar .nav-mobile-wrapper { 
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
    }

    i { 
        color: var(--cWhite);
        cursor: pointer;
    }

    #navBar .nav-sites  { 
        display: none;
    }

    .burger-wrapper { 
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .mobile-img { 
        display: flex;
        align-items: center;
        justify-content: center;
    } 

    .mobile-img img { 
        width: 75px;
    }

    .nav-hamburger-button a { 
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}



.header-blog { 
    cursor: pointer;
}

.header-dropdown { 

    width: 30%;


    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    background-color: var(--cGrey);
    border-radius: 0 10px 10px 10px;

    margin: .5% 0;
    padding: .5%;

    position: absolute;
    z-index: 999;

    a { 
        font-size: 14px;
    }
}

.hidden { 
    display: none;
}
