#WWD { 
    width: 100%;
    height: 100vh;

    position: relative;
    margin: auto;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@keyframes Slide {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 100%;
    }
    
}


.wwd-slide { 
    width: 85%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--cGrey);
    border-radius: 20px;
    overflow: hidden;


}

.wwd-slide-side { 
    width: 50%;
    height: 100%;
}

/* LEFT SIDE */

.wwd-left { 
    width: 1100px;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    flex-wrap: wrap;
    animation: Slide 1s;

}

.wwd-left-text { 
    max-width: 500px;
    z-index: 3;
}

.wwd-left-text h1 { 
    color: var(--cWhite);
    text-transform: uppercase;
    font-size: 2.5rem;
    width: 100%;
    text-align: center;
    font-weight: 900;
    margin: 0;
    line-height: 1.1;
}

.wwd-left-text p { 
    color: var(--cWhite);
    margin: 2%;
    font-weight: 300;
    font-size: 18px;
}

#WWD strong { 
    color: var(--cGreen);
    font-family: var(--aFont);
    font-weight: 900;
}


/* RIGHT SIDE */

.wwd-right { 
    min-width: 500px;
    max-width: 1100px;
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    animation: Slide 1s;


}

.wwd-mid-img img, .wwd-cloud-back img, .wwd-cloud-front img { 
    width: 100%;
}

.wwd-mid-img { 
    z-index: 5;
    width: 600px;
}

.wwd-slide:hover img { 
    transform: scale(1.1) translateX(20px) translateY(-20px);
}

/* Background */

.wwd-background-img { 
    position: absolute;
    width: 50%;
    bottom: -40%;
    left: -30%;
}

/* Dots */


.wwd-dots { 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    background-color: var(--cGrey);
    width: 10%;
    padding: 1% 0;
    border-radius: 0 0 20px 20px;
}

.wwd-dot { 
    width: 20px;
    height: 20px;
    margin: 0 5px;

    cursor: pointer;

    border-radius: 10px;
    background-color: var(--cWhite);
}

@media only screen and (max-width: 1054px) {
    .wwd-right { 
        display: none;
    }

    .wwd-left { 
        width: 100%;
        display: flex;

        h1 { 
            font-size: 26px;
        }

        p { 
            font-size: 16px;
        }
    }

    .wwd-left-text { 
        width: 95%;
    }

    .wwd-background-img { 
        display: none;
    }

    .wwd-dots { 
        display: none;
    }
}