.e-category-section { 
    width: 40%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
    padding-top: 5%;
}


.e-render-category-cards { 
    padding: 0.5%;
    min-height: 40px;
    min-width: 150px;
    background-color: var(--cGrey);
    margin: 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 10px;
    border: 0;

    font-size: 15px;
    font-weight: 600;
    color: var(--cGreen);
    text-transform: uppercase;

    cursor: pointer;
}