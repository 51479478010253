.contact-cert { 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5% 0 0 0;

    .contact-cert-title { 
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .contact-cert-content { 
    }

    img { 
        width: 100px;
    }
}