@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --aFont: 'Kanit', sans-serif;
  --cGreen: #95B704;
  --cDarkBlue: #232C3B;
  --cWhite: #F2F2F2;
  --cGrey: #1c222d;
  --cHeader: #212937;
}

// * { 
//   outline: 1px solid red;
// }

a { 
  text-decoration: none;
}

.alert { 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5px 0;
  background-color: orange;;
  border-radius: 15px;

  p { 
      color: var(--cWhite);
      font-size: 0.9rem;
      font-weight: 400;
      text-align: center;
      width: 90%;
      text-transform: uppercase;;
  }
}

    strong { 
        color: var(--cGreen);
    }



$mbl: 768px;
$tbl: 1400px;

h1, h2, h3, h4, h5, h6, p { 
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
  transition: .5s;
}
html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background: rgb(49,66,93);
  background: radial-gradient(circle, rgba(49,66,93,1) 0%, rgba(28,34,45,1) 100%);
  font-family: var(--aFont);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;

}


.error-page { 
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  padding: 0 10px;

}

.error-page h1, h2 { 
  margin: 0;
  color: var(--cWhite);
  text-align: center;


}

.error-page button { 
  display: flex;
  align-items: center;
  justify-content: center;

  width: 150px;
  height: 40px;
  border-radius: 10px;
  border: none;
  background-color: var(--cGreen);

  margin: 1%;

  color: var(--cWhite);
  font-family: var(--aFont);
  font-weight: 800;
  text-transform: uppercase;

  cursor: pointer;
}

img {  
  // user-drag: none;  
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

:disabled { 
  background-color: red;
  opacity: 10%;
  cursor: not-allowed;
}

li {
  color: var(--cWhite);

  a { 
    color: var(--cGreen);
  }
}