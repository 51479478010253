.eshop-quick-category { 
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    @media only screen and (max-width: 768px) {
        flex-wrap: wrap;
    }

    i { 
        color: var(--cWhite);
        animation: loading 1s ;
        animation-iteration-count: in;
    }
}

@keyframes loading {

    0% {
        transform: rotate(0);
    }

    100% { 
        transform: rotate(360deg);
    }
    
}

.eshop-quick-category-card { 
    display: flex;
    margin: 5% 3%;
    width: 300px;
    height: 180px;
    border-radius: 10px;
    background-color: var(--cWhite);
    overflow: hidden;
    z-index: 0;

    position: relative;

    img { 
        position: absolute;
        top: 0;
        z-index: -2;
        width: 100%;
    }
}

.eshop-quick-category-card:hover { 
    transform: scale(1.05);
}

.eshop-quick-category-shape { 
    width: 180px;
    height: 500px;
    background-color: var(--cGreen);
    position: absolute;
    left: 0;
    top: -240px;
    transform: rotateZ(60deg);
    z-index: -1;
    opacity: 80%;
}

.eshop-quick-category-title {
    padding: 5%;

    @media only screen and (max-width: 768px) {
        width: 100%;

        h3 { 
            width: 100%;
        }
        
    }

    h3 { 
        color: var(--cWhite);
        text-transform: uppercase;
        padding: 0;
        margin: 0;
        font-size: 1rem;
    }
}