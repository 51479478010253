#contact { 
    width: 100%;
    padding: 10% 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
} 


// width: 450px;

.contact-container { 
    background-color: var(--cGrey);
    max-width: 900px;
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap-reverse;
    z-index: 0;

    @media only screen and (max-width: 1054px) { 
        width: 400px;
    }
}

.contact-form { 
    width: 450px;
    background-color: var(--cGreen);
}

.contact-form form { 
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.contact-form-title h1 { 
    padding: 20% 0 0 0;
    color: var(--cWhite);
    font-size: 35px;
    text-transform: uppercase;
}

.contact-form-inputs { 
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1% 5%;
}

.contact-form-inputs input { 
    margin: 2.5% 0;
    width: 100%;
    height: 40px;
    border: 0;
    border-radius: 10px;
    padding: 0 4%;
    font-family: var(--aFont);
    font-weight: 300;
}

.contact-form-inputs textarea { 
    width: 100%;
    border: 0;
    border-radius: 15px;
    padding: 2%;
    font-family: var(--aFont);
    font-weight: 300;
    resize: none;
}

.contact-textarea textarea::placeholder { 
}

.contact-textarea { 
    width: 100%;
    margin: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.contact-textarea button { 
    margin: 5%;
    width: 100%;
    height: 50px;
    border: 0;
    border-radius: 10px;
    background-color: var(--cDarkBlue);
    color: var(--cWhite);
    font-family: var(--aFont);
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
}

.contact-left { 
    width: 450px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    padding: 1%;
}

.contact-left-block { 
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
}

.contact-left-block h2 { 
    text-transform: uppercase;
    color: var(--cWhite);
}

.contact-left-block i { 
    color: var(--cGreen);
}

.contact-block-info { 
    width: 70%;
    padding: 3% 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    color: var(--cWhite);
}

.contact-block-info a { 
    color: var(--cGreen);
}
