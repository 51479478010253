#Partners { 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10% 7%;
    h1 { 
        color: var(--cWhite);
    }
}

.partners-title { 
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1% 0;
    color: var(--cGreen);
    h1 { 
        font-size: 35px;
    }
}

.partners-images-holder { 
    background-color: white;
    border-radius: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    .partner { 
        margin: 0 3%;
        width: 250px;
        height: 150px;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .partner:hover { 
        transform: scale(1.05);
        cursor: pointer;
    }

    img { 
        width: 80%;
    }
}

