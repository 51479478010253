.product-page {
    margin: 5% 0; 
    padding: 0 10% ;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.product-page-error { 
    padding: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    background-color: rgba(0, 0, 0, 0.2);

    border-radius: 10px;
    
    h1 { 
        color: var(--cWhite);
        text-transform: uppercase;

    }

    button { 
        margin: 5%;
        width: 150px;
        height: 30px;
        background-color: var(--cGreen);
        border: 0;
        border-radius: 10px;

        font-family: var(--aFont);
        font-weight: 600;
        color: var(--cWhite);
        cursor: pointer;

    }
}

.product-page-suggestion { 
    margin: 5% 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    h1 { 
        color: var(--cGreen);
        text-transform: uppercase;
        margin: 1% 1%;

    }
}