.re-product-page { 
    display: flex;
    justify-content: space-between;

    width: 100%;
    background-color: white;
    padding: 1% 0;
    border-radius: 10px;
    
    overflow: hidden;


    @media only screen and (max-width: 768px) {
        justify-content: center;
        flex-wrap: wrap;
        height: initial;
    }
}


.re-product-images { 
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .re-product-images-picker { 

        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;

        padding-top: 5%;

        img {
            width: 50px;
            cursor: pointer;
        }

        img:hover { 
            transform: scale(1.1);
        }
    }

    .re-product-images-picker-bc { 
        padding: 1%;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }
}

.re-product-half { 
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    width: 100%;
    height: 100%;

    img { 
        width: 60%;
        aspect-ratio: 1/1;
        object-fit: contain;
    }
}

.re-product-info { 
    width: 100%;
    height: 100%;
    padding-top: 10%;

    justify-content: initial;

    .re-product-category { 
        width: 100%;
        display: flex;
        a { 
            color: var(--cGreen);
            margin: 0 1%;
        }

        @media only screen and (max-width: 768px) {
            justify-content: center;
            align-items: center;
        }
    }

    .re-product-title { 
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: left;
        
        h1 { 
            color: var(--cGreen);
            font-size: 32px;
            width: 90%;
        }

        @media only screen and (max-width: 768px) {
            justify-content: center;
            align-items: center;

            h1 { 
                font-size: 20px;
                text-align: center;
            }
        }
    }

    .re-product-rating { 
        display: flex;
        align-items: center;
        p { 
            display: flex;
            align-items: center;
            margin: 0 1%;
            font-size: 10px;
        }

        @media only screen and (max-width: 768px) {
            justify-content: center;
            align-items: center;
            margin-top: 5% ;
        }
    }

    .re-product-description { 
        padding: 5% 0;
        display: flex;
        flex-direction: column;

        p { 
            width: 80%;
            font-size: 14px;
        }

        @media only screen and (max-width: 768px) {
            justify-content: center;
            align-items: center;
            font-weight: 300;

            p { 
                text-align: center;
            }
        }
    }

    .re-product-price { 
        width: 100%;
        padding: 7% 0;
        display: flex;
        align-items: center;
        justify-content: left;
        
        h1 { 
            color: var(--cGreen);
        }

        .re-product-purchase { 
            display: flex;
            align-items: center;
            justify-content: center;

            @media only screen and (min-width: 768px) {
                margin-right: 30px;
            }
            
            button { 
                background-color: var(--cGreen);
                border: 0;
                width: 150px;
                height: 40px;
                border-radius: 10px;

                text-align: center;
                color: var(--cWhite);
                font-family: var(--aFont);
                font-size: 17px;
                font-weight: 600;

                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;

                cursor: pointer;
            }
        }

        @media only screen and (max-width: 768px) {
            justify-content: center;
            align-items: center;
            flex-direction: column;

        }
    }
}