#cennik { 
    padding: 10% 0;
    width: 100vw;
    min-height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#cennik ul li:before {    
    font-family: 'FontAwesome';
    content: '\f00c';
    margin: 0 3%;
    color: var(--cGreen);
}

.cenova-ponuka { 
    font-size: 40px;
    color: var(--cGreen);
    margin-bottom: 1%;
    text-transform: uppercase;
}

.cennik-info { 
    color: var(--cWhite);
    font-size: 14px;
    font-weight: 100;
    text-align: center;
    width: 50%;
    margin: 10px 0;
}

.cennik-sla-info-title { 
    color: var(--cWhite);
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    width: 70%;

    padding: 0 10%;
}



.cennik-vyhody-title { 
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3% 0;
    width: 100%;
    flex-direction: column;
}

.cennik-vyhody-title h1 { 
    font-size: 30px;
    color: var(--cWhite);
    text-transform: uppercase;
}

.cennik-list-container { 
    padding: 3% 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.cennik-list-left-side {
    width: 100%;
    max-width: 350px;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    background-color: var(--cGrey);

    border-radius: 20px;
}

.cennik-vyhody { 
    width: 90%;
    color: var(--cWhite);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10% 10%;
}


.cennik-vyhody-desc { 
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10% 10%;
    color: var(--cWhite);
    font-size: 13px;
    font-weight: 200;
}

.cennik-vyhody-desc p { 
    text-align: justify;
    word-break:keep-all;
}


/* LIST */
.cennik-vyhody ul { 
    display: flex;
    align-items:center ;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    margin: 0;
}

.cennik-vyhody li { 
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    font-weight: 300;
    color: var(--cWhite);
    width: 100%;
    margin: 1% 0;
}

/* LIST END */

.cennik-vyhody-top { 
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: var(--cWhite);
    padding: 10% 0;

    padding-top: 17%;

    text-align: center;

}

.cennik-vyhody-top h1 { 
    font-size: 28px;
    color: var(--cGreen);
}

.cennik-vyhody-top p { 
    font-weight: 500;
    font-size: 20px;
}


.cennik-right-side { 
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 40%;
}



/* Rows */
.cennik-select-row { 
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 150px;
    border-radius: 20px;
    border: none;
    margin: 10px 0;
    cursor: pointer;
    background-color: var(--cGrey);
    transition: 0.1s;
    padding: 1% 1%;

    color: var(--cWhite);

    overflow: hidden;
}




/* Row Hover */
.cennik-select-row:hover { 
    transform: scale(1.01);
    border: 1px solid var(--cGreen);
}

/* Row Inside */

.cennik-rows { 
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    width: 33%;
    margin: 1%;
}

.cennik-rows h1 { 
    font-size: 24px;
}

.cennik-rows p { 
    font-size: 18px;
}


.cennik-row-start { 
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;

    position: relative;
}
.cennik-row-start input[type="radio"] {
    appearance: none;
    background-color: var(--cDarkBlue);
    margin: 0;
    font: inherit;
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.cennik-row-start input[type="radio"]:checked {
    appearance: none;
    background-color: var(--cGreen);
    margin: 0;
    font: inherit;
    width: 25px;
    height: 25px;
    border-radius: 50%;
}


.cennik-row-mid { 
    display: flex;
    align-items: flex-start;
    justify-content: center;
    text-transform: uppercase;
    width: 50%;
}

.cennik-row-end { 
    margin-left: auto;
}


.cennik-email-redict { 
    margin-top: 2%;

    h5 { 
        color: var(--cGreen);
        text-decoration: underline;
    }

    h5:hover { 
        color: var(--cWhite);
    }
}



/* Cennik Continue Btn */

.cennik-continue {
    margin: 1%;
    width: 150px;
    height: 40px;
    border-radius: 10px;
    border: none;

    font-family: var(--aFont);
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    background-color: var(--cGreen);
    cursor: pointer;

    color: var(--cWhite);
}

:disabled { 
    background-color: rgba(255, 0, 0, 0.3);
    color: var(--cDarkBlue);
    cursor:not-allowed;
}


/* MAIN Row */

.cennik-m { 
    background-color: var(--cGrey);
    color: var(--cGrey);
}

.cennik-m h1 { 
    color: var(--cGreen);
}

.cennik-m p { 
    color: var(--cWhite);
}




@media only screen and (max-width: 768px) { 

    .cennik-list-container { 
        background-color: transparent;
        width: 100vw;
        flex-direction: column-reverse;
    }

    .cennik-select-row { 
        display: flex;
        align-content: center;
        justify-content: center;
        padding: 1%;
        margin: 3px;
        width: 95%;
        height: 100px;
    }

    .cennik-right-side { 
        width: 100%;
        margin: 1% 0;
    }

    .cennik-sla-info-title {
        width: 100%;
     }

    
}