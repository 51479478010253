.eshop-header { 
    width: 100%;
    padding: 1% 0;
    display: flex;
    gap: 1%;

    a { 
        color: var(--cWhite);
        font-weight: 400;
        text-transform: uppercase;

    }
}

.eshop-header-category { 
    width: 100%;
    height: 200px;
    background-color: red;
}