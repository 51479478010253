.onas { 
    padding: 3% 15%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.onas-holder { 
    padding: 2% 5%;

    width: 100%;

    .onas-title { 
        margin: 4% 0;
        h1 { 
            color: white;
            font-family: var(--aFont);
            font-weight: 800;
            font-size: 23px;
        }
    }

}

.onas-middle { 
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;

    .onas-logo { 
        width: 300px;
        height: 300px;

        display: flex;
        align-items: center;
        justify-content: center;

        img { 
            width: 100%;
        }
    }

    .onas-desc { 
        width: 60%;

        p { 
            color: var(--cWhite);
            font-size: 15px;
        }
    }

    @media only screen and (max-width: 768px) {

        .onas-logo { 
            width: 150px;
            height: 150px;
        }
        .onas-desc { 
            width: 100%;
        }
    }
}

.onas-policy { 
    padding: 5% 0 0% 0;
    display: flex;
    align-items: center;
    justify-content: center;
    a { 
        margin: 0 1%;
        color: var(--cGreen);
        font-weight: 300;
        text-decoration: underline;
    }

    a:hover { 
        color: var(--cWhite);
    }
}

.onas-tech { 
    margin-top: 20%;
    .onas-images { 
        
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        padding: 1% 1%;
        border-radius: 10px;

        background-color: var(--cGrey);
        img { 
            width: 150px;
            aspect-ratio: 3/2;
            object-fit: contain;
            margin: .1% .1%;
        }
    }
}