.blog { 
    padding: 5% 20%;

}

.blog-container { 
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;

    padding: 2%;
    background-color: var(--cGrey);
    border-radius: 10px;
}

.blog-header { 
    display: flex;
    align-items: start;
    flex-direction: column;

    h2 { 
        color: var(--cGreen);
        font-size: 30px;
        font-weight: 600;
    }

    h4 { 
        color: var(--cWhite);
        font-weight: 200;
    }

    p { 
        font-size: 15px;
        font-weight: 200;
        color: var(--cWhite);
    }
}

.blog-content { 
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .blog-content-title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: left;

        h3{ 
            color: var(--cGreen);
            text-transform: uppercase;
            margin: 2% 0;
        }
    }

    .blog-content-desc { 
        h3 { 
            text-transform: uppercase;
            margin: 3% 0;
            color: var(--cWhite);
        }
        p { 
            color: var(--cWhite);
            font-weight: 200;
        }
    }
    padding: 3% 0;
}