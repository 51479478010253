#VideoShow { 
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--cWhite);
    position: relative;
}

.videosection-container { 
    width: 90%;
    height: 70%;
    background-color: var(--cGrey);

    border-radius: 10px;

    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    position: relative;

    h2 { 
        font-size: 4rem;
        margin: 0;
        font-weight: 800;
        color: var(--cGreen);
    }

    p { 
        font-size: 1rem;
        font-weight: 300;
        margin: 0;
        padding: 0;
        text-align: center;
        color: var(--cWhite);
    }

    @media only screen and (max-width: 1068px) {
        h2 { 
            color: var(--cWhite);
        }

        p { 
            color: var(--cWhite);
        }
    }
}

.videosection-container:hover .videosection-video-content  { 
    transform: scale(1.05);
}

.videosection-container * { 
    z-index: 888;
}

.videoshow-content{ 
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
}


.videosection-info { 
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    width: 80%;
    height: 100%;
    /* background-color: var(--cGreen); */

    border-radius: 15px;
}


.videosection-desc { 
    width: 80%;
}


.videosection-video-content { 
    display: flex;
    align-content: center;
    justify-content: center;
    width: 80%;
    height: 387px;

    @media only screen and (max-width: 1054px) { 
        width: 100%;
        height: 200px;
    }

}

.videoshow-video { 
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    align-content: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.videosection-container img { 
    position: absolute;
    top: -300px;
    right: -300px;
    width: 800px;
}


@media only screen and (max-width: 1054px) { 

    .videosection-container { 
        flex-wrap: wrap;
        padding: 5%;
    }

    .videoshow-content {
        margin: 5%;
        width: 800px;
    }

    .videosection-info { 
        width: 100%;
    }

    .videosection-header h2 { 
        font-size: 2.5rem;
    }

}