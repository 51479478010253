#cennik-menu { 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .cennik-menu-title {
        padding: 1% 0;
        padding-top: 5%;

        h1 { 
            color: var(--cGreen);
            font-size: 35px;
            text-transform: uppercase;
            font-weight: 600;
        }
    }

}

.cennik-menu-select { 
    height: 70%;
    width: 70%;
    padding: 2%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-bottom: 5% 0;

    flex-wrap: wrap;;

    .cennik-menu-option { 
        background-color: var(--cGrey);
        width: 400px;
        height: 500px;
        margin: 10px 0;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.50);
    }


    .cennik-menu-option:hover { 
        transform: scale(1.05);
        cursor: pointer;
    }

    @media only screen and (max-width: 768px) {
        width: 100%;
    }
}


.cennik-menu-option-img { 
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    img {
        width: 50%;
    }

}

.cennik-menu-option:hover img { 
    transform: scale(1.1)
}


.cennik-menu-option-title { 
    display: flex;;
    align-items: center;
    justify-content: center;
    padding: 5% 3%;

    h2 { 
        color: var(--cGreen);
        font-size: 25px;
        text-transform: uppercase;
    }
}


.cennik-menu-option-description { 
    display: flex;;
    align-items: center;
    justify-content: center;
    width: 100%;

    p {
        color: var(--cWhite);
        text-align: center;
        width: 90%;
    }
}