.cookies-docs { 
    padding: 5% 20%;

    h1 { 
        color: var(--cGreen);
    }

    h3 { 
        color: var(--cGreen);
    }

    p { 
        color: var(--cWhite);
    }

    a { 
        color: var(--cGreen);
        text-decoration: underline;
    }
}