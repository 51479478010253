.search-prod-body { 
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 3% 0;

    form { 
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;


        input { 
            width: 80%;
            height: 40px;
            border-radius: 10px 0 0 10px;
            border: 0;

            font-family: var(--aFont);
            font-weight: 600;
            text-transform: uppercase;
            padding: 0 1%;
        }

        button { 
            width: 10%;
            height: 40px;
            border: 0;
            background-color: var(--cGreen);
            border-radius: 0 10px 10px 0;
            cursor: pointer;

            text-transform: uppercase;

            font-family: var(--aFont);
            font-weight: 600;
            color: var(--cWhite);
        }

        ::placeholder { 
            color: var(--cGreen);
        }
    }

    
    .search-title { 
        width: 90%;
        display: flex;
        align-items: flex-start;
        
        h2 { 
            text-transform: uppercase;
            color: var(--cGreen);
            margin: 1% 0;
        }
    }
}
