
.popup-button { 
    position: fixed;
    bottom: 3%;
    right: 2%;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    border: 0;
    background-color: var(--cGreen);
    cursor: pointer;
    color: var(--cWhite);

    z-index: 999;


}

.popup-container { 
    position: fixed;
    bottom: 3%;
    right: 2%;
    width: 350px;
    height: 450px;
    background-color: var(--cGreen);
    border-radius: 15px;

    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;

    z-index: 999;


}

.popup-container-close {
    width: 100%;
    height: 40px;

    display: flex;
    align-items: flex-end;
    justify-content: end;
    padding: 0 5%;
}

.popup-container-close button { 
    border-radius: 50px;
    border: none;
    width: 30px;
    height: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: red;

    font-weight: 200;

    cursor: pointer;
}

.popup-container-header { 
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup-container-header h2 { 
    text-transform: uppercase;
    color: var(--cWhite);
    margin: 0;
}

.popup-container-form { 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
    margin: auto;

    width: 95%;
}

.popup-container-form input { 
    width: 150px;
    height: 40px;
    border-radius: 10px;
    border: none;

    text-transform: uppercase;

    display: flex;
    align-items: center;
    justify-content: center;

    margin: 10px 3px;
    padding: 0 10px;
    font-weight: 200;

}

.popup-container-form { 
    ::placeholder { 
        display: flex;
        align-items: center;
        justify-content: center;
    
        text-transform: uppercase;
    
        font-weight: 200;
    }
}


#popup-submit-button { 
    width: 100%;
    height: 50px;

    border: none;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 5px 0;


    background-color: var(--cDarkBlue);

    font-family: var(--aFont);
    font-size: 20px;
    text-transform: uppercase;
    color: var(--cWhite);
    font-weight: 600;

    cursor: pointer;
}

.popup-container-submit { 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.gdpr { 
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;

    label { 
        margin: 3px;
        font-size: 13px;
    }
}

#accept-gdpr { 
    display: flex;
    border: none;
    border-radius: 10px;
    background-color: var(--cDarkBlue);
    width: 15px;
}

.popup-container-form input[type=email] { 
    width: 100%;
    text-transform: none;
}



:focus { 
    outline: none;
}

#Popup textarea { 
    border: none;
    border-radius: 10px;
    width: 100%;
    height: 150px;
    margin: 5px 0;

    font-family: var(--aFont);

    padding: 5px;

    resize: none;
    
}

#Popup { 
    textarea::placeholder { 
        display: flex;
        align-items: center;
        justify-content: center;
    
        font-weight: 400;
    }
}

#popup-submit-button:disabled { 
    opacity: 30%;
    cursor:not-allowed;
}

.success-send {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    flex-direction: column;
    text-align: center;
    display: none;
    color: var(--cDarkBlue);
}


.wrongDiv { 
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    height: 40px;
    border-radius: 0px 00px 10px 10px;
    background-color: rgb(255, 49, 49);
    color: var(--cWhite);
    font-weight: 500;

    position: absolute;
    top: 0;

    margin: 0;

    animation: WrongDiv 4.5s;
}

.success-image { 
    animation: SuccessAnim 2s ;
}

@media only screen and (max-width: 1400px) {

}

@keyframes SuccessAnim {
    0% {transform: rotate(180deg);}
    25% {transform: rotate(0deg);}
} 

@keyframes WrongDiv {
    0% {opacity: 100%;}
    50% {opacity: 100%;}
    100% {opacity: 0%;}
} 


/* ATTRIBUTES */

.show { 
    display: flex;
}

#show { 
    display: flex;
}

#hidden { 
    display: none;
}

.hidden { 
    display: none;
}