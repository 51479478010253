#DEV { 
position: fixed;
z-index: 999999;
}


.cross { 
z-index: 9999;
background-color: red;
}

.vert { 
position: fixed;
top: 50%;
height: 1px;
width: 100%;
}

.vert-sec { 
position: fixed;
top: 25%;
height: 1px;
width: 100%;
}

.vert-third { 
position: fixed;
top: 75%;
height: 1px;
width: 100%;
}

.hor { 
position: fixed;
left: 50%;
height: 100%;
width: 1px;
}

.hor-sec { 
position: fixed;
left: 25%;
height: 100%;
width: 1px;
}

.hor-third { 
position: fixed;
left: 75%;
height: 100%;
width: 1px;
}

.dev-controll { 
    display: flex;
}

label { 
    color: white;
}

.disable { 
    display: none;
}

.BARS { 
    z-index: 99999;
}


