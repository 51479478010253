.product-suggestion { 
    width: 100%;
    padding: 2% 0;
    background-color: var(--cGrey);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

}

.pro-sugg-holder { 
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}   


.pro-sugg-card { 
    width: 200px;
    height: 300px;
    background-color: var(--cWhite);

    margin: 1%;

    @media only screen and (max-width: 768px) {
        margin: 10p;
    }
    border-radius: 10px;

    .pro-sugg-img { 
        width: 100%;
        padding: 10%;
        display: flex;
        justify-content: center;
        img { 
            width: 50%;
            aspect-ratio: 1/1;
            object-fit: contain;
        }
    }

    .pro-sugg-title { 
        height: 30%;

        padding: 5%;
        h2 { 
            font-size: 16px;
            color: var(--cGreen);
        }
    }

    .pro-sugg-price { 
        margin: 5% 0;
        display: flex;
        align-items: center;
        justify-content: center;

        color: var(--cGreen);
    }

    .pro-sugg-button { 
        display: flex;
        align-items: center;
        justify-content: center;

        button { 
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100px;
            height: 30px;

            border: 0;
            border-radius: 5px;
            background-color: var(--cGreen);
            cursor: pointer;

            font-family: var(--aFont);
            font-weight: 600;
            text-transform: uppercase;
            color: var(--cWhite);


            
            i { 
                margin: 0 1%;
            }
        }
    }

}