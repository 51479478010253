.return-policy { 
    padding: 5% 20%;

    h1 { 
        color: var(--cGreen);
    }

    p { 
        color: var(--cWhite);
    }
}