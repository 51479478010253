.gdpr { 
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 20px;

    p { 
        color: var(--cWhite);
        margin: 3px;
        font-size: 13px;
    }
}

#accept-gdpr { 
    display: flex;
    border: none;
    border-radius: 10px;
    background-color: var(--cDarkBlue);
    width: 15px;
}