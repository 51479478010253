#eshop { 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 15%;
    padding: 0 15%;

    @media only screen  and  (max-width: 1700px) {
        padding: 0 10%;
    }
}