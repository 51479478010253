.cookies-minimized { 
    position:absolute;
    bottom: 0px;
    right: 10%;
    z-index: 999;

    border-radius: 10px 10px 0 0;

    background-color: var(--cGreen);
    width: 150px;
    height: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    p { 
        color: var(--cWhite);
        font-family: var(--aFont);
        font-weight: 600;
    }
}

.cookies-minimized:hover { 
    height: 35px;

    cursor: pointer;
}


@keyframes CookiesPopup {
    0% {
        transform: scale(0) translate(-50%, -50%)
    }
    100% {
        transform: scale(1) translate(-50%, -50%)
    }
}


.cookies-popup { 
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.3);
    // Position
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;

    animation-name: CookiesPopup;
    animation-duration: 1s;
    animation-iteration-count: 1;

    width: 600px;
    padding: 1%;
    border-radius: 5px 5px 0 5px ;

    background-color: white;

    h3 { 
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
        color: var(--cGreen);
    }

    p { 
        font-size: 12px;
        font-weight: 300;
        font-family: var(--aFont);
    }

    .cookies-desc { 
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        background-color: rgba(128, 128, 128, 0.3);
        padding: 1%;
        border-radius: 5px;
    }

    .cookies-links { 
        padding-top: 20px;
        display: flex;
        flex-direction: column;

        .cookies-terms { 
            margin-top: 2%;
            margin-bottom: 0;
            font-size: 13px;
            color: var(--cGreen);
            text-decoration: underline;
        }
    }

    .cookies-preferences { 
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        padding: 2% 0;

        :disabled { 
            cursor: not-allowed;
        }

        .cookies-option { 
            width: 100%;
            label { 
                margin: 0 1%;
            }
        }

        label { 
            color: var(--cDarkBlue);
            font-size: 12px;
        }
    }

    .cookies-button {
        width: 100px;
        height: 30px;
        margin: 0 5px;
        border: none;
        background-color: var(--cGreen);
        border-radius: 5px;

        text-align: center;
        font-weight: 600;
        color: var(--cWhite);
    }

    .cookies-button:hover {
        opacity: 70%;
        cursor: pointer;
    }
}


@media only screen and (max-width: 768px) { 
    .cookies-popup { 
        width: 90%;
        .cookies-buttons { 
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: right;
        }
    }
}