#SLA { 
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    flex-wrap: wrap;
}

/* Containers */
.sla-purchase-details { 
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 10%;
    padding: 2% 2%;

    background-color: var(--cGrey);
    border-radius: 15px;
}

.sla-purchase-details h1 { 
    text-transform: uppercase;
    color: var(--cWhite);
}

.sla-info { 
    margin: 2% 0;
    display: flex;
    justify-content: space-between;
    padding-bottom: 2%;
    border-bottom: 1px solid var(--cWhite) ;
}

.sla-info p { 
    color: var(--cWhite);
}

.sla-info-title { 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 1%;
    color: var(--cWhite);
}


/* Containers END */

/* Inputs Divs */

.sla-contact-details { 
    padding-top: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.sla-contact-name { 
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Inputs */

.sla-purchase-details input { 
    width: 250px;
    height: 40px;
    border-radius: 10px;
    border: 0;
    padding: 2%;
    margin: 0 1% ;

    font-family: var(--aFont);
    font-size: 16px;
    font-weight: 300;
}


.sla-purchase-details ::placeholder {
    color: var(--cGrey);

    font-family: var(--aFont);
    font-size: 16px;
    font-weight: 200;
}

.sla-inputs { 
    margin: 3% 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.sla-inputs p { 
    position: absolute;
    top: -25px;
    left: 10px;
    color: var(--cGreen);
}

.sla-contact { 
    flex-direction: column;
}

.sla-contact > input { 
    width: 490px;
    margin-bottom: 2%;
}


.sla-submit-button { 
    width: 100%;
    margin: 2% 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.sla-submit-button button { 
    width: 300px;
    height: 35px;

    border-radius: 10px;
    border: 0;
    margin: 2% 0;

    background-color: var(--cGreen);
    font-family: var(--aFont);
    font-weight: 600;
    font-size: 20px;
    color: var(--cWhite);

    cursor: pointer;
}

.sla-contact-textarea { 
    width: 78%;
}

.sla-inputs textarea { 
    width: 400px;
    width: 100%;
    border-radius: 10px;
    padding: 1%;
    font-family: var(--aFont)
}

.sla-purchase-details strong { 
    display: flex;
    align-items: center;
    justify-content: center;
}

.sla-slider { 
    width: 100%;
}

#device-range { 
    width: 85%;
}

@media only screen and (max-width: 768px) {
    .sla-purchase-details {
        width: 90%;
        padding: 5% 2%;
    }

    .sla-purchase-details form { 
        margin: 15% 0;
    }

    .sla-purchase-details input, .sla-purchase-details textarea, .sla-purchase-details button { 
        width: 90%;
    }

    .sla-info-title { 
        width: 100%;
    }
    .sla-info { 
        width: 100%;
    }

    .sla-contact-details { 
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .sla-inputs { 
        flex-direction: column;
        width: 100%;
        margin: 5% 0;
    }

    .sla-inputs input { 
        margin: 1% 0;
    }


    .sla-contact-textarea { 
        width: 100%;
    }

    .sla-contact-textarea textarea { 
        width: 80%;
    }
}

@media only screen and (max-width: 1054px) { 
    .sla-purchase-details {
    }
}

