.admin-panel { 
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 5% 5%;
}

.admin-editor { 
    width: 100%;
    height: 100%;
    .admin-inputs { 
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        height: 50px;

        margin: 5% 0;


        input { 
            width: 200px;
            height: 30px;
            border-radius: 5px;
            border: none;
            margin: 1% 1%;
            text-align: center;
        }
    }

    .rich-text-editor { 
        background-color: white;
        width: 100%;
        margin: 1% 0;

        li { 
            color: black;
        }

        @media only screen and (max-width: 768px) {
            margin: 5% 0;
        }
    }

    .admin-button { 
        width: 200px;
        height: 30px;
        border-radius: 10px;
        border: 0;
        background-color: var(--cGreen);

        font-family: var(--aFont);
        font-size: 17px;
        text-transform: uppercase;
        color: var(--cWhite);
        font-weight: 600;
        cursor: pointer;

    }

    :disabled { 
        background-color: red;
        opacity: 50%;
    }

    .admin-button-area { 
        margin: 1% 0;
    }

}

.admin-side { 
    height: 100%;
    margin: 1%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: var(--cGrey);
    padding: 2%;
    border-radius: 10px;
    max-width: 800px;
    min-height: 300px;


    .admin-title { 
        color: white;
        margin: 0;
        padding: 0;
    }
}

.admin-edit-panel { 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10% 0;

    h1 { 
        color: var(--cWhite);
        text-transform: uppercase;
    }
}


.admin-content-container {

    @media only screen and (max-width: 768px) {
        width: 100%;
    }

    a { 
        color: var(--cGreen);
    }

    a:hover { 
        color: white;
    }


    background-color: var(--cGrey); 
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .react-quill-container { 
        width: 100%;
        color: white;
    }

    .admin-preview-info { 
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        color: var(--cGreen);

    }

    .admin-preview-title {
        color: var(--cGreen);
        font-size: 30px;
    }
}

.admin-data-cards { 
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .admin-card { 
        display: flex;
        flex-direction: column;
        width: 400px;
        min-height: 70px;
        border-radius: 10px;
        display: flex;
        background-color: var(--cWhite);
        margin: 10px;

        padding: 1%;

        textarea { 
            font-size: 10px;
            text-align: center;
            border: 0;
            background-color: transparent;
            min-width: 100%;
            max-width: 100%;

        }

        ::-webkit-resizer {
            background: var(--cGreen);
            border: 1px solid var(--cGrey);
          }

        h3 { 
            color: var(--cGreen);
        }

        .admin-card-head { 
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 5%;

            a { 
                color: var(--cGreen);
                text-decoration: none;
                text-transform: lowercase;
            }
        }

        .admin-card-update-btn { 
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 3%;
            padding: 1%;

            .admin-btn-remove { 
                background-color: rgb(255, 68, 68);
            }
    
            button { 
                cursor: pointer;
                margin: 1% ;
                width: 100px;
                height: 30px;
                border-radius: 10px;
                border: 0;
                background-color: var(--cGreen);

                font-family: var(--aFont);
                font-size: 16px;
                text-transform: uppercase;
                color: var(--cWhite);
                font-weight: 600;

                display: flex;
                align-items: center;
                justify-content: center;
    
            }

            button:hover { 
                transform: scale(1.1);
            }
        }

    }
}