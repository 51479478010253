#Footer { 
    min-height: 200px;
    display: flex;
    align-items: stretch;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;

    color: var(--cWhite);
    text-decoration: none;
    text-align: left;

    padding: 1% 0;

    i { 
        color: var(--cGreen);
        margin-right: 10px;
    }

    @media screen and (max-width: 768px) { 
        height: inherit;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 10% 0;
    }
}

.footer-logo { 
    width: 300px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img { 
        width: 100px;
    }

    p { 
        font-size: 10px;
        font-weight: 300;
        width: 150px;
        margin: 10px 0;
        text-align: center;
        color: var(--cWhite);
    }

    @media only screen and (max-width: 768px) { 
        justify-content: center;
        align-items: center;
        margin-top: 10%;
    }

}



.footer-wrapper { 

    
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    
    @media only screen and (max-width: 768px) { 
        display: flex;
        align-items: center;
        flex-direction: column;
    }

}

.footer-wrapper-contant { 
    height: 100%;
    margin-right: 100px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h3 { 
        margin: 0;
        padding: 0;
        color: var(--cWhite);
    }

    @media only screen and (max-width: 768px) { 
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 5% 0;
    }
}


.footer-info-center { 
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    ul { 
        padding: 0;
        margin: 0;
    }

    li { 
        list-style:none;
        margin: 0;
        padding: 0;
    }

    a { 
        color: var(--cWhite);
        text-decoration: none;
        transition: .5s;
    }

    a:hover { 
        color: var(--cGreen);
    }

    @media only screen and (max-width: 768px) { 
        .footer-info-center { 
            justify-content: center;
            align-items: center;
            text-align: center;
        }
    }
}


.footer-extra { 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    a { 
        color: var(--cGreen);
        text-decoration: underline;
    }

    @media only screen and (max-width: 768px) { 
        justify-content: center;
        align-items: center;
    }
}



@media only screen and (max-width: 768px) { 

    .footer-extra-center { 
        justify-content: center;
        align-items: center;
    }

    .footer-extra-center ul { 
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column;
        text-align: left;;
    }
}
