#slaComplete { 
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10%;
}

.slaComplete-container { 
    padding: 10%;
    height: 60%;
    background-color: var(--cGrey);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.slaComplete-container h1 { 
    color: var(--cGreen);
    text-align: center;
    font-weight: 800;
    font-size: 30px;
}

.slaComplete-container p { 
    color: var(--cWhite);
}

.slaComplete-container button { 
    width: 200px;
    height: 40px;
    border-radius: 10px;
    border: 0;
    background-color: var(--cGreen);
    color: var(--cWhite);
    font-family: var(--aFont);
    font-weight: 500;
    font-size: 20px;
    cursor: pointer;
}

.slaComplete-content { 
    margin: 15px 0;
}