#AboutUs { 
    display: flex;
    align-items: center;
    justify-content: left;
    flex-wrap: wrap;
    position: relative;
    flex-direction: column;
    padding: 3% 20%;


    a { 
        text-decoration: none;
    }


    @media only screen and (max-width: 768px) { 
        padding: 3% 5%;
    }
}

#AboutUs * { 
    z-index: 22;
}


.aboutus-content {
    padding: 1% 0;
    display: grid;
    grid-template-columns: 0fr 0fr;
    gap: 10px;
    grid-template-areas: "header header";
    width: 100%;
    justify-content: center;
    align-content: center;

    flex-wrap: wrap;


@media only screen and (max-width: 1028px) { 
    display: flex;
}
}

.aboutus-title { 
    padding: 10%;
    border-radius: 10px;
    width: 90%;
    text-align: center;
    display: none;
    align-content: center;
    justify-content: center;
    flex-direction: column;

    h1 { 
        font-size: 2rem;
        font-weight: 600;
        color: var(--cGreen);
        width: 100%;
        text-align: center;
    }

    p { 
        color: var(--cWhite);
        font-weight: 300;
    }

    @media only screen and (max-width: 764px) { 
        h1 { 
            font-size: 1.5rem;
        }
    }
}


.aboutus-card { 
    border-radius: 20px;
    background-color: var(--cWhite);
    width: 500px;
    height: 500px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;


    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);

    padding: 7%;

    cursor: pointer;

}


.aboutus-card:hover { 
    transform: scale(1.02);
    cursor: pointer;
}

.aboutus-header-card { 
    grid-area: header;
    grid-template-columns: 3fr 1fr;
    background-color: var(--cGrey);
    width: 100%;
    cursor: pointer;
}

/* Card Content */


.aboutus-header-card-content { 
    display: flex;
    align-content: flex-start;
    justify-content: flex-start;

    width: 60%;
    height: 100%;


    flex-direction: column;

}

.aboutus-card-title { 
    color: var(--cWhite);
    width: 100%;
    height: 100%;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

}

.aboutus-card-desc { 
    color: var(--cWhite);
    width: 100%;
    height: 100%;
    margin: 3% 0;
}

.aboutus-card-title h2 { 
    margin: 20px 0;
    font-weight: 600;
    font-size: 1.9rem;
    text-align: left;


}

.aboutus-header-card-content .aboutus-card-title h2 { 
    color: var(--cGreen);
    font-size: 2.2rem;
    line-height: 1;
}

.aboutus-card-desc p { 
    font-weight: 200;
    font-size: 1rem;
    margin: 0;
    width: 50%;
}

.aboutus-card-button, .aboutus-card-button-sub { 
    height: 90%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;

}

.aboutus-card-button button, .aboutus-card-button-sub button { 
    width: 40%;
    height: 30px;

    background-color: transparent;
    border: none;

    color: var(--cWhite);
    font-family: var(--aFont);
    font-weight: 600;
    font-size: 1rem;
    border-radius: 10px;
    transition: .5s;

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.aboutus-card-button-sub button { 
    color: var(--cDarkBlue);
    height: 100%;
    display: flex;
    align-content: center;
    justify-content: space-between;
    text-align: left;
    width: 50%;

    margin-top: 10%;

}

.aboutus-another-img { 
    position: absolute;
    right: 0;
    bottom: 10%;
    width: 50%;

    transition: .5s;
}

.aboutus-card:hover img { 
    transform: scale(1.1) translateX(10px) translateY(-10px);
    
}

.aboutus-card:hover .aboutus-card-button button  {
    width: 45%;
    background-color: var(--cGreen);
}

.aboutus-card:hover .aboutus-card-button-sub button { 
    width: 55%;
    background-color: var(--cDarkBlue);
    color: var(--cWhite);
}

.aboutus-squid { 
    width: 40%;
    position: absolute;
    bottom: 0;
    right: 0;
    transition: .5s;
    z-index: 0;
}


@media only screen and (max-width: 764px) { 


    .aboutus-card-desc p { 
        font-size: .9rem;
    }
    
    .aboutus-header-card-content { 
        width: 100%;
    }

    .aboutus-card-button { 
        height: 100%;
    }

    .aboutus-card-button button { 
        height: 30px;
        display: none;
    }

    .aboutus-card-button-sub button { 
        display: none;
    }


    .aboutus-card-title h2 { 
        font-size: 1.2rem;
        width: 100%;
    }
    .aboutus-content { 
        display: flex;
        align-content: center;
        justify-content: center;

    }

    .aboutus-card { 
        width: 300px;
        height: 500px;
    }

    .aboutus-squid { 
        width: 100%;
        right: -50%;
    }
}

@media only screen and (max-width: 1054px) { 

    .aboutus-card { 
        width: 500px;
        height: 500px;
    }
    .aboutus-header-card { 
        grid-area: header;
        grid-template-columns: 3fr 1fr;

        width: 100%;
    }
}

.dark { 
    color: var(--cDarkBlue);
}