#sluzby {
    width: 100%;
    min-height: 1000px;
    display: flex;
    flex-direction: row;

    @media only screen  and  (max-width: 1400px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }   
}

.sluzby-left-panel {
    position: sticky;
    top: 20px;
    left: 20px;
    background-color: var(--cGrey);
    width: 300px;
    flex: 1.8;
    height: 100%;
    display: flex;
    flex-direction: column;

    border-radius: 15px 15px;
    margin-top: 2%;

    padding: 3% 1%;

    @media only screen  and  (max-width: 1400px) {
        justify-content: center;;
        align-items: center;
        flex: 1;
        margin-bottom: 5%;
        position: inherit;
    }   
}

.sluzby-left-row { 
    width: 100%;

    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin: 3% 0;
    padding: 2% 0;
    
    a { 
        width: 100%;
        padding: 2% 0;
        text-decoration: none;
    }
    

    h3 { 
        color: var(--cGreen);
        text-transform: uppercase;
        font-size: 14px;
        padding: 0 5%;
    }
    :hover h3 { 
        color: var(--cWhite);
    }
}




.sluzby-left-panel p { 
    font-size: 13px;
    text-align: left;
    color: var(--cWhite);
}

.sluzby-left-buttons { 
    padding: 10% 0;
}

/* Sluzby Site */
#sluzby-site { 
    height: 100%;
    flex: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2% 5%;

    @media only screen  and  (max-width: 768px) {
        width: 100%;
    }
}

.sluzby-body { 
    background-color: var(--cGrey);
    width: 70%;
    height: 100%;
    padding: 5%;
    border-radius: 15px;

    @media only screen  and  (max-width: 1028px) {
        width: 100%;
    }
}

.sluzby-header { 
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    padding: 1% 0;
        
    h1 { 
        font-size: 30px;
        text-transform: uppercase;
        color: var(--cGreen);
        text-align: left;;
    }

    .sluzby-header-info { 
        display: flex;
        justify-content: space-between;
        margin: 1% 0;
        width: 100%;

        p { 
            color: var(--cGreen);
            font-size: 12px;
        }
    }
}

.sluzby-content {
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: column;
    text-align: left;

    padding: 1% 0;

    @media only screen  and  (max-width: 768px) {
        width: 100%;
        align-items: flex-start;
        justify-content: start;

    }

    h1, h2, h3 { 
        color: var(--cWhite);
        text-align: left;
        font-weight: 600;
    }

    p { 
        width: 100%;
        color: var(--cWhite);
        margin: 2% 0;

    }

    a { 
        color: var(--cGreen);
    }

    span { 
        color: var(--cWhite);
    }
    
} 

.sluzby-domov-title { 

    h1 { 
        font-size: 25px;
        color: var(--cGreen);
        text-transform: uppercase;
    }

    p { 
        color: var(--cWhite);
        text-transform: uppercase;
    }


}



.sluzby-content-data { 
    margin: 1% 0;

    @media only screen  and  (max-width: 768px) {
        align-items: center;;
        justify-content: center;;
        text-align: centers;
    }   
    ul { 
        width: 50%;
    }

    li {
        color: var(--cWhite);
        font-size: 13px;
        font-weight: 300;
    }
}


