#login-page { 
    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 90vh;


    form { 
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
        background-color: var(--cGrey);

        min-height: 600px;
        padding: 0 3%;

        border-radius: 10px;

        .login-title { 
            color: var(--cWhite);
            text-transform: uppercase;
            text-align: center;

            h1 { 
                font-size: 25px;
            }

            p { 
                font-size: 10px;
                color: var(--cGreen);
            }
        }

        .login-inputs { 
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        button { 
            width: 200px;
            height: 30px;
            border-radius: 10px;
            border: 0;

            font-size: 16px;
            color: var(--cWhite);
            font-family: var(--aFont);
            font-weight: 600;

            background-color: var(--cGreen);
            cursor: pointer;

        }

        :disabled { 
            background-color: var(--cGreen);
            opacity: 20%;
            cursor: not-allowed;
        }

        .login-error { 
            color: red;
            font-weight: 200;
        }
    }

    input { 
        width: 300px;
        height: 35px;
        color: var(--cWhite);

        font-family: var(--aFont);

        margin: 1%;

        border: 0;

        background-color: transparent;
        border-bottom: 1px solid var(--cGreen);
        caret-color: var(--cGreen)
    };

    ::placeholder { 
        color: var(--cWhite);
    }

}

